import type { AxiosInstance, AxiosResponse } from 'axios';

export default abstract class BaseApi {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  get<Res = any>(
    ...args: Parameters<AxiosInstance['get']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.get(...args);
  }

  post<Res = any>(
    ...args: Parameters<AxiosInstance['post']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.post(...args);
  }

  delete<Res = any>(
    ...args: Parameters<AxiosInstance['delete']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.delete(...args);
  }
}
