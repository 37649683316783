import { getColor } from './utils/get-color';

export { COMPASS_SKIN } from './utils/useCompassStyles';

export const DEBOUNCE_INTERVAL = 125;

export const ADDRESS_DEBOUNCE_INTERVAL = 300;

export const GAPI_KEY = process.env.JSAPP_GAPI_KEY;

export const DATE_FORMAT = 'MM/DD/YYYY';
export const YEAR_FORMAT = 'YYYY';

export const DATE_DB_FORMAT = 'MM/DD/YYYY';
export const YEAR_DB_FORMAT = 'YYYY';

export const STYLE_FORM_MAX_WIDTH = '600px';

export const DEFAULT_GUTTER = 16;

export const ADMIN_BASE_URL = '/-site-admin';

export const GREEN = getColor('green-5');
export const BLUE = getColor('app-blue');
export const LIGHT_BLUE = getColor('blue-2');
export const RED = '#E53D41';
export const PURPLE = getColor('app-secondary-color');
export const YELLOW = '#F4F439';
export const ORANGE = getColor('app-accent-color-2');
export const PRIMARY_COLOR = BLUE;
export const SECONDARY_COLOR = PURPLE;
export const TERTIARY_COLOR = GREEN;

export const ERROR_COLOR = '#f49492';

export const BREAD_CRUMB_LEN = 36;
export const APP_REGISTER_URL =
  'https://app.glide.com/auth/register/?code=glidedemo';

export const PRODUCT_LABEL_BY_ID = {
  // Development
  prod_EAYjB4wjwSte8l: 'Glide Premium Individual',
  // Production
  prod_ECN7neeOKomuOO: 'Glide Premium Individual',
};

export const WEBINAR_LINK =
  'https://help.glide.com/en/articles/2272138-how-to-register-for-glide-s-e-learning-or-webinar-training';

export const ALL_WEBINARS_LINK =
  'https://help.glide.com/en/articles/2272138-how-to-register-for-a-glide-webinar-training';

export const EA_WEBINAR_LINK = 'https://vimeo.com/684778364/734992fe63';

export const OFFERS_HELP_LINK =
  'https://help.glide.com/en/collections/2729377-creating-managing-offers';

export const DEMO_LINK = 'https://demo.glide.com';

// Number of seconds to display a fake progress bar and the ability
// to cancel a GFP submit.

export const GFP_SUBMIT_CANCEL_SECS = 4;

export const CHORE_ICON_SIZE = 36;

export const CHORE_ICON_SIZE_SM = 24;

export const CHORE_ICON_SIZE_MINI = 18;

export const CHORE_ICON_SIZE_XS = 14;

export const THUMBNAIL_ICON_SIZE_SM = 32;

export const SUPPORTED_STATES = ['CA', 'FL', 'PA'];

export const TOP_BAR_HEIGHT = 64;

export const SIDE_DRAWER_WIDTH = 315;

export const BASIC_TIERS = ['STARTER', 'BASIC'];

export const PREMIUM_TIERS = ['PRO', 'LEGACY_PRO_TC'];

export const CLIENT_CHORES = [
  'FILL_GFP',
  'ORDER_DPP',
  'PICK_NHD_PROVIDER',
  'MOVING',
];

export const SELECTED_ROLE_LABELS = {
  TC: 'Assistant or Coordinator',
  AGENT: 'Real Estate Agent',
  TC_IN_HOUSE: 'In-house TC',
  TC_INDEPENDENT: 'Independent TC',
  BROKER: 'Broker / Broker Owner',
  CLIENT: 'Real Estate Client',
};

export const SESSION_CAPTURE_ROLES = [
  'TC',
  'AGENT',
  'TC_IN_HOUSE',
  'TC_INDEPENDENT',
];

export const TC_SELECTED_ROLES = ['TC', 'TC_IN_HOUSE', 'TC_INDEPENDENT'];

export const AGENT_SELECTED_ROLES = ['AGENT', 'BROKER'];

export const LEASE_SIDE_MAP = {
  SALE_LEASE: 'SALE',
  PURCHASE_LEASE: 'PURCHASE',
};

export const DEFAULT_TXN_PAGE_SIZE = 40;

export const DOCUMENT_DOWNLOAD_TIMEOUT = 5 * 1000;

export const FLOW_LOADING_TIMEOUT = 5000;

export const SET_EMBEDDED_HEIGHT_TIMEOUT = 300;

export const SYNC_DMS_DATA_TIMEOUT = 2000;

export const TD_KIND_COVER_SHEET = 'COVER_SHEET';

export const COMPASS_URL =
  window.Glide.env === 'production'
    ? 'https://www.compass.com'
    : 'https://gamma.compass.com';

export const FILE_UPLOAD_ACCEPTS = '.pdf,image/*,.doc,.docx,.heic,.heif';

export const GLIDE_DEEPLINK_ORIGIN = 'https://link.glide.com/';
