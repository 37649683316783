import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import getComponentName from '../utils/datadogRouterMap';

if (typeof window.React !== 'object') {
  window.React = React;
  window.ReactDOM = ReactDOM;
}

function getDDRumEnv() {
  return window.Glide.DATADOG_ENV;
}

export default () => {
  if (
    window.Glide.DATADOG_APPLICATION_ID &&
    window.Glide.DATADOG_CLIENT_TOKEN &&
    window.Glide.DATADOG_SITE
  ) {
    datadogRum.init({
      applicationId: window.Glide.DATADOG_APPLICATION_ID,
      clientToken: window.Glide.DATADOG_CLIENT_TOKEN,
      site: window.Glide.DATADOG_SITE,
      service: 'glide-webapp',
      env: getDDRumEnv(),
      version: window.Glide.RELEASE,
      samplerate: 100,
      trackInteractions: true,
      beforeSend: (event) => {
        event.context = {
          ...event.context,
          component: getComponentName(window.router?.getState()),
        };
      },
    });
  }
};
